import React from "react";
import PT from "prop-types";
import { Slider, StoryTileWithContent } from "../../molecules";
import { CollectionName } from "../../atoms";
import { getCollectionName } from "../../helper/utils";
import "./trending-news.m.css";
import { DfpAd } from "../../dfp-ad/index";

export const TrendingCardWithSlider = ({ collection }) => {
  const filteredStories = collection.items.filter(item => item.type === "story");
  return (
    <React.Fragment>
      <div styleName="wrapper" className="topcomponent">
        <div className="container">
          <CollectionName name={getCollectionName(collection)} />
          <div styleName="carousel-wrapper">
            <Slider
              options={{ type: "carousel", perView: 1, autoplay: 7000 }}
              carouselName={`carousel-${collection.id}`}
            >
              {filteredStories.length &&
                filteredStories.map((item, index) => <StoryTileWithContent key={index} story={item.story} />)}
            </Slider>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="dfp-ad dfp-ad-height-trend">
          <DfpAd adtype="FortuneIndia_Mid_1" />
        </div>
      </div>
    </React.Fragment>
  );
};

TrendingCardWithSlider.propTypes = {
  collection: PT.object.isRequired
};
