import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";

import {
  StoryCardWithoutImage,
  HorizontalCardWithImage4x3,
  AuthorWithImageName,
  MultimediaTile
} from "../../molecules";
import { CollectionName, ImageCard4x3, Headline, ByName, SectionName } from "../../atoms";
import { DfpAd } from "../../dfp-ad/index";
import { getCollectionName, getHeadline } from "../../helper/utils";
import "./three-col-with-horizontal-card.m.css";

export const ThreeColWithHorizontalCard = ({ collection }) => {
  const [firstStory, ...restOftheStories] = get(collection, ["items"], []).filter(item => item.type === "story");
  const list3Stories = restOftheStories.slice(0, 3);
  const [thirdStory, fourthStory] = restOftheStories.slice(3, 5);
  const author = firstStory.story.authors[0];
  const authorId = author ? author.id : null;
  let { name } = collection;
  name = name.toLowerCase();

  return (
    <div className="container">
      <CollectionName name={getCollectionName(collection)} />
      <div styleName="wrapper">
        <div styleName="content-borderd">
          {/* 1st Row & 1st Column */}
          {firstStory && (
            <div styleName="two-col-card border-right-btm horizontal-card">
              <div styleName="card-img">
                <Link href={`/${firstStory.story.slug}`}>
                  <ImageCard4x3 story={firstStory.story} />
                </Link>
              </div>
              <div styleName="card-content">
                <Link href={`/${firstStory.story.sections[0].slug}`}>
                  <SectionName name={firstStory.story.sections[0].name} />
                </Link>
                <Link href={`/${firstStory.story.slug}`}>
                  <Headline value={getHeadline(firstStory.story)} fontSize="halfjumbo" lines={3} />
                </Link>
                <ByName authors={firstStory.story.authors} />
              </div>
            </div>
          )}

          {/* 1st Row & 2nd Column */}
          {list3Stories && (
            <div styleName="border-btm card-list">
              {" "}
              {list3Stories.map((item, index) => {
                return <HorizontalCardWithImage4x3 story={item.story} key={index} lines={2} />;
              })}
            </div>
          )}

          {/* 2nd Row & 1st Column */}
          {thirdStory && (
            <div styleName="border-right story-card">
              <div>
                <Link href={`/${thirdStory.story.sections[0].slug}`}>
                  <SectionName name={thirdStory.story.sections[0].name} />
                </Link>
                <Link href={`/${thirdStory.story.slug}`}>
                  <Headline value={getHeadline(thirdStory.story)} fontSize="halfjumbo" lines={3} />
                </Link>
              </div>
              <Link href={`author/${authorId}`}>
                <AuthorWithImageName authors={thirdStory.story.authors} />
              </Link>
            </div>
          )}

          {/* 2nd Row & 2nd Column */}
          {fourthStory && (
            <div styleName="border-right story-card-without-author">
              <MultimediaTile story={fourthStory.story} type="small" showHeading={false} />
              <StoryCardWithoutImage story={fourthStory.story} fontSize="large" lines={3} />
            </div>
          )}

          {/* 2nd Row & 3rd Column */}
          <div styleName="ad" className="dfp-ad-height-280">
            <DfpAd adtype="FortuneIndia_RHS_1" />
          </div>
        </div>
      </div>
      {name !== "magazine" ? (
        <div styleName="middle-ad" className="dfp-ad-height-132">
          <DfpAd adtype="FortuneIndia_Mid_3" />
        </div>
      ) : null}
    </div>
  );
};

ThreeColWithHorizontalCard.propTypes = {
  collection: PT.object.isRequired
};
